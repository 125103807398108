$linear: linear-gradient(to right, #00FFA4, #00C3B8);
$linear-red: linear-gradient(to right, #FF5D5D, #6D3AEF);
$linear-red-vertical: linear-gradient(-120deg, #FF5D5D 30%, #6D3AEF 150%);
$linear-yellow: linear-gradient(to right, #FFD600, #FF5252);
$light-text: #fff;
$bg: #F6F6F6;
$bg-2: #fff;
$shadow: 5px 5px 20px rgba(0,0,0,.03);
$shadow-strong: 5px 5px 10px rgba(0,0,0,.08);
$disable-color: #BFBDC3;
$disable-hover: #6D3AEF;
$bg-input: #F4F4F4;

$company-color: #6D3AEF;
$company-color-hover: #300992;

$item-active: #6D3AEF;
$item-inactive: #BFBDC3;

$text: #7C7985;

$loader-bg: #d8d8d8;

* {  
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.wrapper {
  width: 100%;
  min-height: 100vh;
}

.btn {
  background-color: blue;
  color: white;
  padding: 10px 20px;
}
  
header {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.btn-logout {
  position: absolute;
  top: 20px;
  right: 20px;
  border: 0;
  background: $linear;
  padding: 10px 40px;
  color: $light-text;
  font-weight: 600;
  border-radius: 30px;
  font-size: 1rem;
  cursor: pointer;
  transition: .3s
}

.btn-logout:hover {
  background: #00C3B8;
}

.logo {
  margin: 0 30px;
}

main {
  max-width: 1200px;
  margin: 0 auto;
  background: $bg;
  padding: 20px 80px;
  border-radius: 20px;
  margin-top: -40px;
  z-index: 1;
  position: relative;
  box-shadow: $shadow;
}

.filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 30px;
}

.filters button {
  border: 0;
  padding: 5px 20px;
  font-weight: 600;
  background: 0;
  color: $disable-color;
  transition: .3s;
  cursor: pointer;
}

.filters button:hover {
  color: $disable-hover;
}

.posts-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.post {
  position: relative;
  width: 80%;
  align-self: flex-start;
}

.urgent-tag {
  position: absolute;
  z-index: 1;
  left: -60px;
  top: 25px;
  padding: 7px 20px 30px 20px;
  background: $linear-red-vertical;
  transform: rotate(-90deg);
  font-weight: 600;
  color: #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.post-wrapper {
  width: 95%;
  padding: 40px 2.5%;
  margin-bottom: 30px;
  border-radius: 10px;
  background: $bg-2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  height: 100%;
  box-shadow: $shadow;
  position: relative;
  z-index: 2;
  align-self: flex-start;
}

.post-image {
  min-width: 400px;
  min-height: 400px;
  max-width: 40%;
  max-height: 40%;
  margin: 0;
}

.post-image img {
  width: 100%;
  display: block;
}

.post-content {
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 100%;
}

.post-content h3 {
  color: $text;
}


.post-content-infos {
  width: 100%;
}

.post-content p {
  flex-wrap: wrap;
  white-space: pre-wrap;  // Preserva espaços e quebras de linha
  word-wrap: break-word;  // Quebra palavras longas se necessário
}

.post-scheduled {
  font-weight: 800;
  margin: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 0;
  z-index: 100;
  text-align: center;
  box-shadow: $shadow-strong;
  border-radius: 5px;
  overflow: hidden;
}

.post-scheduled h3 {
  font-weight: 400;
  padding: 0;
  margin: 0;
  padding: 5px 10px;
  font-size: .9rem;
  color: #fff;
  background: $linear;
}

.post-scheduled h2 {
  font-weight: 400;
  padding: 0;
  margin: 0;
  padding: 0 10px;
  font-size: 1.5rem;
  color: $text;
  background: $bg;
}

.post-last-edit {
  font-weight: 400;
  font-size: .85rem;
  color: $text;
  margin: 0 0 10px 0;
}

.post-content-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.post-socials svg {
  fill: $disable-color;
  margin-right: 10px;
}

.post-actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 40%;
  align-self: flex-end;
}

.approved-tag {
  background: $linear;
}

.waiting-tag {
  background: $linear-yellow;
}
.disapproved-tag {
  background: $linear-red;
}

.approved-tag, .waiting-tag, .disapproved-tag{
  flex-grow: 2;
  width: 100%;
  text-align: center;
  font-weight: 500;
  padding: 10px 0;
  color: #fff !important;
  border-radius: 30px;
  line-height: 1.1rem;
  margin: 0 !important;
}

.post-actions h3 {
  flex-grow: 2;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.post-actions button {
  border: 0;
  background: transparent;
  padding: 0 20px;
  cursor: pointer;
}

.post-actions button.btn-approve {
  fill: url(#gradient-approve);
  transition: fill 0.5s ease;
}

.post-actions button.btn-disapprove {
  margin-bottom: -5px;
  fill: url(#gradient-disapprove);  
  transition: fill 0.5s ease;
}

.facebook-active {
  fill: #1877F2;
}

.instagram-active {
  fill: #1877F2;
}

.instagram-active .instagram-path-1 {
  fill: url(#instagram-path-1)
}
.instagram-active .instagram-path-2 {
  fill: url(#instagram-path-2)
}
.instagram-active .instagram-path-3 {
  fill: url(#instagram-path-3)
}

.linkedin-active {
  fill: #007AB9;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-wrapper {
  width: 100%;
}

.swiper-slide {
  width: 100% !important;
  margin: 0 auto;
}

.swiper-pagination {
  bottom: 0 !important;
  top: auto !important;
}


// .swiper-slide img {
//   display: block;
//   width: 100%;
//   height: 100%;
//   // object-fit: cover;
// }

.date-sort-button {
  display: flex;
  align-items: center;
  color: #d3d3d3; /* Cor padrão para o texto */
  font-size: 16px;
  font-weight: bold;
  border: none;
  background: none;
  cursor: pointer;
}

.arrows {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  justify-content: space-between;
  align-items: center;
}

.arrow-up,
.arrow-down {
  transition: fill 0.3s ease;
  fill: $item-inactive;
  margin: 2px 0;
}

.active {
  fill: $item-active; /* Cor ativa */
}

.modal-disapprove {
  position: absolute;
  left: 100%;
  top: 10px;
  width: 0%;
  overflow: hidden;
  background: $bg-2;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  box-shadow: $shadow;
  transition: .3s;
  animation: modal-show .3s forwards;
  padding: 20px 0;
}

@keyframes modal-show {
  0%{
    width: 0%;
  }
  100% {
    width: 30%;
  }  
}

.modal-disapprove-wrapper {
  width: 80%;
  padding: 0 10%;
  display: flex;
  flex-direction: column;
}

.modal-disapprove-title {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.modal-disapprove-title h3 {
  font-size: .9rem;
  font-weight: 400;
  margin-left: 10px;
  color: $text;
}

.modal-disapprove-wrapper textarea {
  width: calc(100% - 30px);
  border-radius: 5px;
  background: $bg-input;
  border: 0;
  outline: none;
  resize: none;
  padding: 15px;
  line-height: 1.2rem;
  min-height: 250px;
  margin-bottom: 10px;
}

.modal-disapprove-actions {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-disapprove-attach {
  opacity: 0;
  position: absolute;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 1;
}

.modal-disapprove-clip {
  border: 0;
  background: transparent;
  cursor: pointer;
}

.modal-disapprove-btn {
  border: 0;
  background: $linear;
  padding: 10px 40px;
  color: $light-text;
  font-weight: 600;
  border-radius: 30px;
  font-size: 1rem;
  cursor: pointer;
  transition: .3s;
  box-shadow: $shadow;
  position: relative;
  z-index: 2;
}

.modal-disapprove-attachments {
  width: 100%;
}

.attachments-list {
  margin: 0;
  padding: 0 10px;
  margin-bottom: 15px;
}

.attachments-list li {
  list-style: none;
  padding: 0;
  margin: -8px 0;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
}

.attachments-list li svg {
  margin-right: 5px;
}

.attachments-list li button {
  border: 0;
  background: transparent;
  cursor: pointer;
}


// login page

.login-page {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  max-width: 400px;
  width: 80%;
}

.login-form input {
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  background: $bg-input;
  border: 0;
}

.login-form input:focus {
  outline: 1px solid $company-color;

}

.login-form label {
  align-self: flex-start;
  font-weight: 700;
  color: $text;
  font-size: .9rem;
  margin-bottom: 5px;
}

.login-form button {
  border: 0;
  border-radius: 30px;
  margin: 10px 0;
  padding: 10px 60px;
  box-shadow: $shadow-strong;
  background: $company-color;
  font-weight: 700;
  color: #fff;
  letter-spacing: .05rem;
  cursor: pointer;
  transition: .3s;
}

.login-form button:hover {
  background: $company-color-hover;
  box-shadow: $shadow-strong;
}

// loader
.loader-wrapper {
  width: 100%;
  height: 100vh;
  margin: 0;
  background: $bg;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 16px;
  height: 16px;
  position: relative;
  left: 0;
  border-radius: 50%;
  color: $loader-bg;
  background: currentColor;
  box-shadow: 32px 0 , -32px 0 ,  64px 0;
}

.loader::after {
  content: '';
  position: absolute;
  left: -32px;
  top: 0;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  background: $company-color;
  animation: move 3s linear infinite alternate;
}

@keyframes move {
  0% , 5%{
    left: -32px;
    width: 16px;
  }
  15% , 20%{
    left: -32px;
    width: 48px;
  }
  30% , 35%{
    left: 0px;
    width: 16px;
  }
  45% , 50%{
    left: 0px;
    width: 48px;
  }
  60% , 65%{
    left: 32px;
    width: 16px;
  }

  75% , 80% {
    left: 32px;
    width: 48px;
  }
  95%, 100% {
    left: 64px;
    width: 16px;
  }
}